export default {
  lineNumberColor: `#a9b7c6`,
  lineNumberBgColor: `#282b2e`,
  backgroundColor: `#282b2e`,
  textColor: `#a9b7c6`,
  substringColor: `#a9b7c6`,
  keywordColor: `#cc7832`,
  attributeColor: `#6A8759`,
  selectorAttributeColor: `#cc7832`,
  docTagColor: `#a9b7c6`,
  nameColor: `#e8bf6a`,
  builtInColor: `#a9b7c6`,
  literalColor: `#6897BB`,
  bulletColor: `#6897BB`,
  codeColor: `#a9b7c6`,
  additionColor: `#6A8759`,
  regexpColor: `#a9b7c6`,
  symbolColor: `#6897BB`,
  variableColor: `#629755`,
  templateVariableColor: `#629755`,
  linkColor: `#629755`,
  selectorClassColor: `#e8bf6a`,
  typeColor: `#ffc66d`,
  stringColor: `#6A8759`,
  selectorIdColor: `#e8bf6a`,
  quoteColor: `#808080`,
  templateTagColor: `#a9b7c6`,
  deletionColor: `#cc7832`,
  titleColor: `#ffc66d`,
  sectionColor: `#ffc66d`,
  commentColor: `#808080`,
  metaKeywordColor: `#a9b7c6`,
  metaColor: `#bbb529`,
  functionColor: `#a9b7c6`,
  numberColor: `#6897BB`,
}
