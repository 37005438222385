export default {
  lineNumberColor: `#dcdcdc`,
  lineNumberBgColor: `#3f3f3f`,
  backgroundColor: `#3f3f3f`,
  textColor: `#dcdcdc`,
  substringColor: `#8f8f8f`,
  keywordColor: `#e3ceab`,
  attributeColor: `#efdcbc`,
  selectorAttributeColor: `#e3ceab`,
  docTagColor: `#dcdcdc`,
  nameColor: `#efef8f`,
  builtInColor: `#cc9393`,
  literalColor: `#efefaf`,
  bulletColor: `#dca3a3`,
  codeColor: `#dcdcdc`,
  additionColor: `#7f9f7f`,
  regexpColor: `#dcdcdc`,
  symbolColor: `#dca3a3`,
  variableColor: `#efdcbc`,
  templateVariableColor: `#efdcbc`,
  linkColor: `#dca3a3`,
  selectorClassColor: `#efef8f`,
  typeColor: `#efef8f`,
  stringColor: `#cc9393`,
  selectorIdColor: `#efef8f`,
  quoteColor: `#7f9f7f`,
  templateTagColor: `#dcdcdc`,
  deletionColor: `#cc9393`,
  titleColor: `#efef8f`,
  sectionColor: `#efef8f`,
  commentColor: `#7f9f7f`,
  metaKeywordColor: `#dcdcdc`,
  metaColor: `#7f9f7f`,
  functionColor: `#dcdcdc`,
  numberColor: `#8cd0d3`,
}
