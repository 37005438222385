export default {
  lineNumberColor: `#839496`,
  lineNumberBgColor: `#002b36`,
  backgroundColor: `#002b36`,
  textColor: `#839496`,
  substringColor: `#cb4b16`,
  keywordColor: `#859900`,
  attributeColor: `#b58900`,
  selectorAttributeColor: `#859900`,
  docTagColor: `#2aa198`,
  nameColor: `#268bd2`,
  builtInColor: `#dc322f`,
  literalColor: `#2aa198`,
  bulletColor: `#cb4b16`,
  codeColor: `#839496`,
  additionColor: `#859900`,
  regexpColor: `#2aa198`,
  symbolColor: `#cb4b16`,
  variableColor: `#b58900`,
  templateVariableColor: `#b58900`,
  linkColor: `#cb4b16`,
  selectorClassColor: `#268bd2`,
  typeColor: `#b58900`,
  stringColor: `#2aa198`,
  selectorIdColor: `#268bd2`,
  quoteColor: `#586e75`,
  templateTagColor: `#839496`,
  deletionColor: `#dc322f`,
  titleColor: `#268bd2`,
  sectionColor: `#268bd2`,
  commentColor: `#586e75`,
  metaKeywordColor: `#839496`,
  metaColor: `#cb4b16`,
  functionColor: `#839496`,
  numberColor: `#2aa198`,
}
