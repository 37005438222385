export default {
  lineNumberColor: `white`,
  lineNumberBgColor: `#002451`,
  backgroundColor: `#002451`,
  textColor: `white`,
  substringColor: `white`,
  keywordColor: `#ebbbff`,
  attributeColor: `#ffeead`,
  selectorAttributeColor: `#ebbbff`,
  docTagColor: `white`,
  nameColor: `#ff9da4`,
  builtInColor: `#ffc58f`,
  literalColor: `#ffc58f`,
  bulletColor: `#d1f1a9`,
  codeColor: `white`,
  additionColor: `#d1f1a9`,
  regexpColor: `#ff9da4`,
  symbolColor: `#d1f1a9`,
  variableColor: `#ff9da4`,
  templateVariableColor: `#ff9da4`,
  linkColor: `#ffc58f`,
  selectorClassColor: `#ff9da4`,
  typeColor: `#ffc58f`,
  stringColor: `#d1f1a9`,
  selectorIdColor: `#ff9da4`,
  quoteColor: `#7285b7`,
  templateTagColor: `white`,
  deletionColor: `#ff9da4`,
  titleColor: `#bbdaff`,
  sectionColor: `#bbdaff`,
  commentColor: `#7285b7`,
  metaKeywordColor: `white`,
  metaColor: `#ffc58f`,
  functionColor: `white`,
  numberColor: `#ffc58f`,
}
