export default {
  lineNumberColor: `black`,
  lineNumberBgColor: `white`,
  backgroundColor: `white`,
  textColor: `black`,
  substringColor: `#000`,
  keywordColor: `#008`,
  attributeColor: `#000`,
  selectorAttributeColor: `#008`,
  docTagColor: `#606`,
  nameColor: `#008`,
  builtInColor: `#606`,
  literalColor: `#066`,
  bulletColor: `#066`,
  codeColor: `black`,
  additionColor: undefined,
  regexpColor: `#080`,
  symbolColor: `#066`,
  variableColor: `#660`,
  templateVariableColor: `#660`,
  linkColor: `#066`,
  selectorClassColor: `#9B703F`,
  typeColor: `#606`,
  stringColor: `#080`,
  selectorIdColor: `#9B703F`,
  quoteColor: `#800`,
  templateTagColor: `black`,
  deletionColor: undefined,
  titleColor: `#606`,
  sectionColor: `#008`,
  commentColor: `#800`,
  metaKeywordColor: `black`,
  metaColor: `#066`,
  functionColor: `black`,
  numberColor: `#066`,
}
