export default {
  lineNumberColor: `#f8f8f2`,
  lineNumberBgColor: `#23241f`,
  backgroundColor: `#23241f`,
  textColor: `#f8f8f2`,
  substringColor: `#f8f8f2`,
  keywordColor: `#f92672`,
  attributeColor: `#66d9ef`,
  selectorAttributeColor: `#f92672`,
  docTagColor: `#f8f8f2`,
  nameColor: `#f92672`,
  builtInColor: `#e6db74`,
  literalColor: `#ae81ff`,
  bulletColor: `#ae81ff`,
  codeColor: `#a6e22e`,
  additionColor: `#e6db74`,
  regexpColor: `#ae81ff`,
  symbolColor: `#66d9ef`,
  variableColor: `#e6db74`,
  templateVariableColor: `#e6db74`,
  linkColor: `#ae81ff`,
  selectorClassColor: `#a6e22e`,
  typeColor: `#e6db74`,
  stringColor: `#e6db74`,
  selectorIdColor: `#e6db74`,
  quoteColor: `#ae81ff`,
  templateTagColor: `#f8f8f2`,
  deletionColor: `#75715e`,
  titleColor: `#a6e22e`,
  sectionColor: `#a6e22e`,
  commentColor: `#75715e`,
  metaKeywordColor: `#f8f8f2`,
  metaColor: `#75715e`,
  functionColor: `#f8f8f2`,
  numberColor: `#ae81ff`,
}
