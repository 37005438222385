export default {
  lineNumberColor: `#4f424c`,
  lineNumberBgColor: `#e7e9db`,
  backgroundColor: `#e7e9db`,
  textColor: `#4f424c`,
  substringColor: `#4f424c`,
  keywordColor: `#815ba4`,
  attributeColor: `#fec418`,
  selectorAttributeColor: `#815ba4`,
  docTagColor: `#4f424c`,
  nameColor: `#ef6155`,
  builtInColor: `#f99b15`,
  literalColor: `#f99b15`,
  bulletColor: `#48b685`,
  codeColor: `#4f424c`,
  additionColor: `#48b685`,
  regexpColor: `#ef6155`,
  symbolColor: `#48b685`,
  variableColor: `#ef6155`,
  templateVariableColor: `#ef6155`,
  linkColor: `#ef6155`,
  selectorClassColor: `#ef6155`,
  typeColor: `#f99b15`,
  stringColor: `#48b685`,
  selectorIdColor: `#ef6155`,
  quoteColor: `#776e71`,
  templateTagColor: `#4f424c`,
  deletionColor: `#f99b15`,
  titleColor: `#fec418`,
  sectionColor: `#fec418`,
  commentColor: `#776e71`,
  metaKeywordColor: `#4f424c`,
  metaColor: `#ef6155`,
  functionColor: `#4f424c`,
  numberColor: `#f99b15`,
}
