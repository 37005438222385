export default {
  lineNumberColor: `#0ff`,
  lineNumberBgColor: `#000080`,
  backgroundColor: `#000080`,
  textColor: `#0ff`,
  substringColor: `#0ff`,
  keywordColor: `#fff`,
  attributeColor: `#ff0`,
  selectorAttributeColor: `#fff`,
  docTagColor: `#888`,
  nameColor: `#fff`,
  builtInColor: `#ff0`,
  literalColor: `#0f0`,
  bulletColor: `#ff0`,
  codeColor: `#0ff`,
  additionColor: `#ff0`,
  regexpColor: `#0f0`,
  symbolColor: `#ff0`,
  variableColor: `#fff`,
  templateVariableColor: `#ff0`,
  linkColor: `#0f0`,
  selectorClassColor: `#fff`,
  typeColor: `#fff`,
  stringColor: `#ff0`,
  selectorIdColor: `#fff`,
  quoteColor: `#888`,
  templateTagColor: `#ff0`,
  deletionColor: `#888`,
  titleColor: `#0ff`,
  sectionColor: `#fff`,
  commentColor: `#888`,
  metaKeywordColor: `#0ff`,
  metaColor: `#008080`,
  functionColor: `#0ff`,
  numberColor: `#0f0`,
}
