export default {
  lineNumberColor: `#ddd`,
  lineNumberBgColor: `#272822`,
  backgroundColor: `#272822`,
  textColor: `#ddd`,
  substringColor: `#a6e22e`,
  keywordColor: `#f92672`,
  attributeColor: `#bf79db`,
  selectorAttributeColor: `#f92672`,
  docTagColor: undefined,
  nameColor: `#f92672`,
  builtInColor: `#a6e22e`,
  literalColor: `#f92672`,
  bulletColor: `#a6e22e`,
  codeColor: `#66d9ef`,
  additionColor: `#a6e22e`,
  regexpColor: `#bf79db`,
  symbolColor: `#bf79db`,
  variableColor: `#a6e22e`,
  templateVariableColor: `#a6e22e`,
  linkColor: `#bf79db`,
  selectorClassColor: `#ddd`,
  typeColor: `#a6e22e`,
  stringColor: `#a6e22e`,
  selectorIdColor: undefined,
  quoteColor: `#75715e`,
  templateTagColor: `#a6e22e`,
  deletionColor: `#75715e`,
  titleColor: `#a6e22e`,
  sectionColor: `#a6e22e`,
  commentColor: `#75715e`,
  metaKeywordColor: `#ddd`,
  metaColor: `#75715e`,
  functionColor: `#ddd`,
  numberColor: `#ddd`,
}
