export default {
  lineNumberColor: `#e3dfff`,
  lineNumberBgColor: `#2d2b57`,
  backgroundColor: `#2d2b57`,
  textColor: `#e3dfff`,
  substringColor: `#e3dfff`,
  keywordColor: `#fb9e00`,
  attributeColor: `#4cd213`,
  selectorAttributeColor: `#fb9e00`,
  docTagColor: `#e3dfff`,
  nameColor: `#a1feff`,
  builtInColor: `#fb9e00`,
  literalColor: `#fa658d`,
  bulletColor: `#4cd213`,
  codeColor: `#4cd213`,
  additionColor: `#4cd213`,
  regexpColor: `#4cd213`,
  symbolColor: `#4cd213`,
  variableColor: `#e3dfff`,
  templateVariableColor: `#e3dfff`,
  linkColor: `#e3dfff`,
  selectorClassColor: `#4cd213`,
  typeColor: `#e3dfff`,
  stringColor: `#4cd213`,
  selectorIdColor: `#e3dfff`,
  quoteColor: `#4cd213`,
  templateTagColor: `#4cd213`,
  deletionColor: `#4cd213`,
  titleColor: `#fad000`,
  sectionColor: `#fb9e00`,
  commentColor: `#ac65ff`,
  metaKeywordColor: `#e3dfff`,
  metaColor: `#fb9e00`,
  functionColor: `#e3dfff`,
  numberColor: `#fa658d`,
}
