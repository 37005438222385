export default {
  lineNumberColor: `#b9b5b8`,
  lineNumberBgColor: `#322931`,
  backgroundColor: `#322931`,
  textColor: `#b9b5b8`,
  substringColor: `#b9b5b8`,
  keywordColor: `#c85e7c`,
  attributeColor: `#dd464c`,
  selectorAttributeColor: `#c85e7c`,
  docTagColor: `#b9b5b8`,
  nameColor: `#dd464c`,
  builtInColor: `#fd8b19`,
  literalColor: `#fd8b19`,
  bulletColor: `#8fc13e`,
  codeColor: `#b9b5b8`,
  additionColor: `#8fc13e`,
  regexpColor: `#dd464c`,
  symbolColor: `#8fc13e`,
  variableColor: `#dd464c`,
  templateVariableColor: `#dd464c`,
  linkColor: `#dd464c`,
  selectorClassColor: `#dd464c`,
  typeColor: `#fd8b19`,
  stringColor: `#8fc13e`,
  selectorIdColor: `#dd464c`,
  quoteColor: `#989498`,
  templateTagColor: `#b9b5b8`,
  deletionColor: `#dd464c`,
  titleColor: `#1290bf`,
  sectionColor: `#1290bf`,
  commentColor: `#989498`,
  metaKeywordColor: `#b9b5b8`,
  metaColor: `#149b93`,
  functionColor: `#1290bf`,
  numberColor: `#fd8b19`,
}
