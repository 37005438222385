export default {
  lineNumberColor: `#e6e1dc`,
  lineNumberBgColor: `#232323`,
  backgroundColor: `#232323`,
  textColor: `#e6e1dc`,
  substringColor: `#519f50`,
  keywordColor: `#c26230`,
  attributeColor: `#cda869`,
  selectorAttributeColor: `#c26230`,
  docTagColor: `#e6e1dc`,
  nameColor: `#e8bf6a`,
  builtInColor: `#6d9cbe`,
  literalColor: `#e6e1dc`,
  bulletColor: `#6d9cbe`,
  codeColor: `#e6e1dc`,
  additionColor: `#e6e1dc`,
  regexpColor: `#a5c261`,
  symbolColor: `#6d9cbe`,
  variableColor: `#a5c261`,
  templateVariableColor: `#a5c261`,
  linkColor: `#6d9cbe`,
  selectorClassColor: `#9b703f`,
  typeColor: `#da4939`,
  stringColor: `#a5c261`,
  selectorIdColor: `#8b98ab`,
  quoteColor: `#bc9458`,
  templateTagColor: `#e6e1dc`,
  deletionColor: `#e6e1dc`,
  titleColor: `#ffc66d`,
  sectionColor: `#ffc66d`,
  commentColor: `#bc9458`,
  metaKeywordColor: `#e6e1dc`,
  metaColor: `#9b859d`,
  functionColor: `#e6e1dc`,
  numberColor: `#a5c261`,
}
