export default {
  lineNumberColor: `#000000`,
  lineNumberBgColor: `#FFFFDF`,
  backgroundColor: `#FFFFDF`,
  textColor: `#000000`,
  substringColor: `#000000`,
  keywordColor: `#006666`,
  attributeColor: `#924B72`,
  selectorAttributeColor: `#000000`,
  docTagColor: `#000000`,
  nameColor: `#000000`,
  builtInColor: `#006666`,
  literalColor: `#924B72`,
  bulletColor: `#000000`,
  codeColor: `#006666`,
  additionColor: `#00AAAA`,
  regexpColor: `#00AAAA`,
  symbolColor: `#924B72`,
  variableColor: `#006666`,
  templateVariableColor: `#000000`,
  linkColor: `#924B72`,
  selectorClassColor: `#006666`,
  typeColor: `#000000`,
  stringColor: `#0080FF`,
  selectorIdColor: `#924B72`,
  quoteColor: `#000000`,
  templateTagColor: `#000000`,
  deletionColor: `#924B72`,
  titleColor: `#006666`,
  sectionColor: `#00AAAA`,
  commentColor: `#00AAAA`,
  metaKeywordColor: `#006666`,
  metaColor: `#924B72`,
  functionColor: `#000000`,
  numberColor: `#000000`,
}
