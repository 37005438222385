export default {
  lineNumberColor: `#c0c5ce`,
  lineNumberBgColor: `#2b303b`,
  backgroundColor: `#2b303b`,
  textColor: `#c0c5ce`,
  substringColor: `#c0c5ce`,
  keywordColor: `#b48ead`,
  attributeColor: `#ebcb8b`,
  selectorAttributeColor: `#b48ead`,
  docTagColor: `#c0c5ce`,
  nameColor: `#bf616a`,
  builtInColor: `#d08770`,
  literalColor: `#d08770`,
  bulletColor: `#a3be8c`,
  codeColor: `#c0c5ce`,
  additionColor: `#a3be8c`,
  regexpColor: `#bf616a`,
  symbolColor: `#a3be8c`,
  variableColor: `#bf616a`,
  templateVariableColor: `#bf616a`,
  linkColor: `#d08770`,
  selectorClassColor: `#bf616a`,
  typeColor: `#d08770`,
  stringColor: `#a3be8c`,
  selectorIdColor: `#bf616a`,
  quoteColor: `#65737e`,
  templateTagColor: `#c0c5ce`,
  deletionColor: `#bf616a`,
  titleColor: `#8fa1b3`,
  sectionColor: `#8fa1b3`,
  commentColor: `#65737e`,
  metaKeywordColor: `#c0c5ce`,
  metaColor: `#d08770`,
  functionColor: `#c0c5ce`,
  numberColor: `#d08770`,
}
