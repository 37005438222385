export default {
  lineNumberColor: `#eaeaea`,
  lineNumberBgColor: `black`,
  backgroundColor: `black`,
  textColor: `#eaeaea`,
  substringColor: `#eaeaea`,
  keywordColor: `#c397d8`,
  attributeColor: `#e7c547`,
  selectorAttributeColor: `#c397d8`,
  docTagColor: `#eaeaea`,
  nameColor: `#d54e53`,
  builtInColor: `#e78c45`,
  literalColor: `#e78c45`,
  bulletColor: `#b9ca4a`,
  codeColor: `#eaeaea`,
  additionColor: `#b9ca4a`,
  regexpColor: `#d54e53`,
  symbolColor: `#b9ca4a`,
  variableColor: `#d54e53`,
  templateVariableColor: `#d54e53`,
  linkColor: `#e78c45`,
  selectorClassColor: `#d54e53`,
  typeColor: `#e78c45`,
  stringColor: `#b9ca4a`,
  selectorIdColor: `#d54e53`,
  quoteColor: `#969896`,
  templateTagColor: `#eaeaea`,
  deletionColor: `#d54e53`,
  titleColor: `#7aa6da`,
  sectionColor: `#7aa6da`,
  commentColor: `#969896`,
  metaKeywordColor: `#eaeaea`,
  metaColor: `#e78c45`,
  functionColor: `#eaeaea`,
  numberColor: `#e78c45`,
}
