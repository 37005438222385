export default {
  lineNumberColor: `#f8f8f8`,
  lineNumberBgColor: `#000`,
  backgroundColor: `#000`,
  textColor: `#f8f8f8`,
  substringColor: `#daefa3`,
  keywordColor: `#96cbfe`,
  attributeColor: `#ffffb6`,
  selectorAttributeColor: `#96cbfe`,
  docTagColor: `#ffffb6`,
  nameColor: `#96cbfe`,
  builtInColor: `#f8f8f8`,
  literalColor: `#c6c5fe`,
  bulletColor: `#c6c5fe`,
  codeColor: `#f8f8f8`,
  additionColor: `#a8ff60`,
  regexpColor: `#e9c062`,
  symbolColor: `#c6c5fe`,
  variableColor: `#c6c5fe`,
  templateVariableColor: `#c6c5fe`,
  linkColor: `#e9c062`,
  selectorClassColor: `#f8f8f8`,
  typeColor: `#ffffb6`,
  stringColor: `#a8ff60`,
  selectorIdColor: `#ffffb6`,
  quoteColor: `#7c7c7c`,
  templateTagColor: `#f8f8f8`,
  deletionColor: `#ff73fd`,
  titleColor: `#ffffb6`,
  sectionColor: `#ffffb6`,
  commentColor: `#7c7c7c`,
  metaKeywordColor: `#f8f8f8`,
  metaColor: `#7c7c7c`,
  functionColor: `#f8f8f8`,
  numberColor: `#ff73fd`,
}
